import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { login } from "../../store/user"
import { getAPI } from "../../store/api";

import { useDispatch } from 'react-redux'
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

import logo from '../../Assets/hubble_logo.svg';
import {createTheme, ThemeProvider} from "@mui/material/styles";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://hubblenetwork.com/">
                hubblenetwork.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function SignIn() {
    const darkTheme = createTheme({
        palette: {
            mode: "dark",
            background: {
                default: "#131139",
                paper: "#ffffff",
            },
            primary: {
                main: "#5A64FF"
            },

            secondary: {
                main: "#171442"
            },
            text: {
                primary: "#FFFFFF",
                secondary: "#B3B3B3",
            },
        },
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        '&:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 100px #29246e inset',
                            WebkitTextFillColor: '#ffffff',
                            caretColor: '#ffffff',
                            borderRadius: 'inherit',
                        },
                    },
                    root: {
                        '&.Mui-focused': {
                            '& .MuiInputBase-input': {
                                '&:-webkit-autofill': {
                                    WebkitBoxShadow: '0 0 0 100px #171442 inset',
                                    WebkitTextFillColor: '#FFFFFF',
                                },
                            },
                        },
                    },
                },
            }
        },
    });

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("UserName/Password must not empty!");
    const dispatch = useDispatch()
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (!data.get('email') || !data.get('password')) {
            setMessage("UserName/Password must not empty!");
            setOpen(true);
        }
        else {
            dispatch(getAPI({
                "method": "POST",
                "data": {
                    "login": {
                        "email": `${data.get('email')}`,
                        "password": `${data.get('password')}`
                    }
                }
            })).then(res =>{
                console.log(res);
                if(res.payload.login.error){
                    setMessage(res.payload.login.error);
                    setOpen(true);
                }
                else{
                    dispatch(login(res.payload));
                }
            })
        }
    }

    const responseMessage = (response) => {
        dispatch(login(jwt_decode(response.credential)))
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    const handleClose = (event, reason) => {
        setOpen(false);
    };


    return (

        <ThemeProvider theme={darkTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        mt: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={logo} width={200} alt={"logo"} />
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 5 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, py: 1.5}}
                        >
                            Sign In
                        </Button>
                        <Grid container sx={{ mt: 3 }}>
                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <GoogleLogin onSuccess={responseMessage} onError={errorMessage} ></GoogleLogin>
                            </Grid>
                            {/*<Grid item>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>*/}
                        </Grid>
                    </Box>
                </Box>
                <Snackbar
                    open={open}

                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    autoHideDuration={3000}

                    onClose={handleClose}
                    message={message}
                />
                <Copyright sx={{ mt: 5, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
