import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';

import moment from 'moment';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import HistoryMap from './historyMap';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import IconClose from "../icons/iconClose";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90vw",
    height: "90dvh",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
    p: 1
};

function CustomizedTables({ rows,places, name, setRefresh }) {
    //console.log(rows);
     const [tableRow, selectTableRow] = React.useState(false);
    const matches = useMediaQuery('(max-width:600px)')
    
    return (
        <div>
            <Grid container spacing={0.3}>
                <Grid sx={{ marginTop: "-5px" }} container justifyContent={"center"} item xs={12}>
                    <Chip clickable={true} color='primary' sx={{ fontWeight: "bold", my: "6px" }} size='small' label={name.assetName}/>
                </Grid>
                <Grid item lg={4} xl={4} md={4} sm={4} xs={12} >
                    <TableContainer sx={{ height: (matches ? "calc(40dvh - 25px)" : "calc(90dvh - 60px)") }} component={Paper}>
                        <Table stickyHeader size={"small"} sx={{ minWidth: 450 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell >Place</StyledTableCell>
                                    <StyledTableCell align="right">Check In</StyledTableCell>
                                    <StyledTableCell align="right">Duration</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row,idx) => (

                                    <TableRow onClick={() => { selectTableRow(row) }} key={idx}>
                                        <StyledTableCell>{row.place}</StyledTableCell>
                                        <StyledTableCell>{row.reportTime}</StyledTableCell>
                                        <StyledTableCell>{row.duration}</StyledTableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
                    <div style={{ height: (matches ? "calc(60dvh - 25px)" : "calc(90dvh - 60px)") }}>
                        <HistoryMap height={(matches ? "calc(60dvh - 25px)" : "calc(90dvh - 60px)")} rows={rows} places={places} row={tableRow} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12
    }
}));

const CalculateData = (show, dataInfo) => {
    var newData = [...dataInfo[show.id]];

    return newData.map((item) => ({
        ...item,
        id: show.id,
        assetName: show.assetName,
        place: item.addr?item.addr:item.place ? item.place.label : (item.lat.toFixed(6) + ", " + item.lng.toFixed(6)),
        reportTime: moment.unix(item.utc).fromNow(),
        duration: item.dur?moment().subtract(item.dur, 'seconds').fromNow(true):"-"
    }));
}

export default function HistoryTable({ show, openChart, dataH, needRefresh }) {
    const [refresh, sRefresh] = React.useState(false)

    const forceRefresh = React.useCallback((data) => {
        if (data) sRefresh(true);
    }, [sRefresh])
    return (
        <div>
            <Modal
                open={(show) ? true : false}
                title={show.assetName}
                onClose={() => { openChart(false); if (refresh) needRefresh(true); }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ position: "fixed", right: -10, top: -4, zIndex: 9999,}}>
                    <Button variant='text' onClick={() => {
                        openChart(false);
                        if (refresh) needRefresh(true);
                    }}  sx={{padding: "14px"}}>
                        <IconClose fill="#232323"/>
                    </Button>
                </div>
                <CustomizedTables rows={CalculateData(show, dataH)} places={dataH.places} name={show} setRefresh={(data) => { forceRefresh(data) }}></CustomizedTables>
            </Box>
            </Modal>
        </div>
    );
}