import * as React from 'react';
import Maps from './dashMaps';
import DashTable from './sortDashTable';
import HistoryTable from './historyTable';
import { useDispatch } from 'react-redux';
import { getAPI } from "../../store/api";
import Grid from '@mui/material/Grid';
import Navbar from '../navbar/navbar';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux'
import { login } from "../../store/user"
import _ from 'lodash';

const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';


export default function Dashboard({ navIsOpen, setNavIsOpen }) {
    const {user} = useSelector(state => state.user)
    const oldUser = React.useRef(0);
    const [historyPop, showHistoryPop] = React.useState(false);
    const [deviceData, setDeviceData] = React.useState(false);
    const [placeData, setPlaceData] = React.useState(false);
    const [historyData, setHistoryData] = React.useState(false);
    const [searchItem, setSearchItem] = React.useState(false);
    const [search, setSearch] = React.useState(false);
    const [tableToMap, setTableToMap] = React.useState(false);
    const [forceRefresh, setForceRefresh] = React.useState(0);
    const [childRefresh, setChildRefresh] = React.useState(false);
    const [userDetail, setUserDetail] = React.useState(false);
    const [map2Table, setMap2Table] = React.useState(false);
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width:600px)');

    React.useEffect(() => {
        if (userDetail &&  !_.isEqual(oldUser.current,user)) {
            dispatch(login({"login":user.login, "detailUser":userDetail }));
            oldUser.current = user;
        }
    }, [dispatch, user, userDetail])

    React.useEffect(() => {
        if (forceRefresh !== 2) {
            dispatch(getAPI({
                "method": "POST",
                "data": {
                    "token": "",
                    "place": {"list":{}},
                    "assetData": {
                        "latest": {}
                    },
                    "user": {
                        "detailUser": {}
                    },
                    "group": {
                        "list": {}
                    }
                }
            })).then(res => {
                //console.log(res)
                if (res && res.payload && !res.payload.error) {                    
                    
                    var dData = JSON.parse(JSON.stringify(res.payload.assetData.latest));

                    for(var j in dData){
                        //console.log(dData[j]);
                        for(var k in res.payload.group.list){
                            if(dData[j].groupID === res.payload.group.list[k].id){
                                dData[j].groupName = res.payload.group.list[k].label;
                            }
                        }
                    }

                    
                    const promises = dData.map(item => {
                        const url = `${geocodeJson}?key=AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM&latlng=${item.latest.lat},${item.latest.lng}`;
                        return fetch(url).then(response=> { return response.json() });
                    });
                
                    
                    Promise.all(promises).then(results => {
                        const locations = results.map(result => result.results[0].formatted_address);
                        for(var j in dData){
                            dData[j]["addr"] = locations[j];
                        }
                        setDeviceData(dData);
                    })

                    var gData = JSON.parse(JSON.stringify(res.payload.group.list));

                    for(var v in gData){
                        gData[v].value = gData[v].label;
                        gData[v].type = "Group";
                    }
                    var sData = JSON.parse(JSON.stringify(res.payload.assetData.latest));
                    for(var x in sData){
                        sData[x].value = sData[x].label;
                        sData[x].type = "Asset";
                    }
                    setSearchItem([{"label":"Asset", options:sData},{"label":"Group", options:gData}]);
                    setPlaceData(res.payload.place.list)            
                    setUserDetail(res.payload.user.detailUser)
                    if (forceRefresh) {
                        setForceRefresh(2);
                        setChildRefresh(true);
                    }
                }
            })
        }

    }, [dispatch, forceRefresh])

    const openChartPOP = (val) => { // the callback. Use a better name
        setHistoryData(false)
        if (val) {
            dispatch(getAPI({
                "method": "POST",
                "data": {
                    "token": "",                    
                    "assetData": {
                        "history": {
                            "id": val.id
                        }
                    }
                }
            })).then(res => {
                var history = res.payload.assetData.history;
                
                const promises = history[val.id].map(item => {
                    const url = `${geocodeJson}?key=AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM&latlng=${item.lat},${item.lng}`;
                    return fetch(url).then(response=> { return response.json() });
                });
            
                
                Promise.all(promises).then(results => {
                    const locations = results.map(result => result.results[0].formatted_address);
                    //console.log(locations);
                    
                    let newData = history[val.id].map((item,i) => 
                        Object.assign({}, item, {"addr":locations[i]})
                    )
                    const newHistory = {[val.id]:newData};
                    setHistoryData({...newHistory, "places":placeData});
                })
            })
        }
        showHistoryPop(val)
    };
    return (
        <Box sx={{ display: "flex" }}>
            <Navbar listItems={searchItem} searchData={(val)=>{setSearch(val)}} open={navIsOpen} setOpen={setNavIsOpen} />
            <Box component={"main"} sx={{ flexGrow: 1, marginTop: matches?7:8 }}>
               { <Grid container spacing={0.3}>
                    <Grid item lg={4} xl={4} md={4} sm={4} xs={12} >
                        <div style={{ marginTop: 0, height: (matches ? "calc(40dvh - 32px)" : "calc(100dvh - 64px)") }}>
                            {deviceData ? <DashTable search={search} openChart={openChartPOP} refresh={childRefresh} deviceDataList={deviceData} mapSearch={(data) => { setTableToMap(data) }} map2Table={map2Table}></DashTable> : <></>}
                        </div>
                    </Grid>
                    <Grid item xl={8} lg={8} md={8} sm={8} xs={12} >
                        <div style={{ marginTop: 0, height: (matches ? "calc(60dvh - 26px)" : "calc(100dvh - 64px)") }}>
                            {deviceData ? <Maps search={search} table2Map={tableToMap} openChart={openChartPOP} deviceDataList={deviceData} Map2Table={(data) => { setMap2Table(data) }} places={placeData} navIsOpen={navIsOpen}/> : <></>}
                        </div>
                    </Grid>
                    {historyPop && typeof historyData === "object" ? <HistoryTable show={historyPop} dataH={historyData} openChart={openChartPOP} needRefresh={(data) => { setForceRefresh(data ? 1 : 2) }} /> : <></>}
    </Grid>}
            </Box>
        </Box>
    )
}