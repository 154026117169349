import React from "react";
import {ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";

const MenuItem = ({title, setTitle, open, path, Icon, isMain = false, onClick}) => {
    const navigate = useNavigate();
    const itemTitle = path.replace(/^\/|\/$/g, '');
    const isActive = itemTitle === title || (isMain && title === '');

    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            navigate(path);
            setTitle(itemTitle);
        }
    };

    return (
        <Tooltip title={open ? "" : itemTitle} placement={"right"}>
            <ListItem key={path} sx={{display: 'block', px: open ? 2 : "0.2rem", py: 0}}>
                <ListItemButton
                    onClick={handleClick}
                    sx={{
                        minHeight: 48,
                        minWidth: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        borderRadius: 4,
                        mx: open ? 1 : 0,
                        my: "0.3rem",
                        backgroundColor: (theme) => isActive ? theme.palette.secondary.dark : "transparent",
                        opacity: isActive ? 0.9 : 0.8,
                        color: "#c6c7e4",
                        '& .MuiSvgIcon-root': {
                            color: '#FFF'
                        },

                        '&:hover': {
                            color: "#FFF",
                            // opacity: 1,
                            background: "rgba(13, 11, 41, 0.6)",
                            '.MuiSvgIcon-root': {
                                opacity: 1,
                                color: '#FFF', // Change icon color on hover
                            },
                        },
                    }}
                >
                    <ListItemIcon
                        className="menu_item_icon"
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}>
                        {Icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={itemTitle}
                        sx={{
                            opacity: open ? 1 : 0,
                            '& span': {
                                fontSize: "0.9rem",
                                letterSpacing: 0.7,
                            }
                        }}/>
                </ListItemButton>
            </ListItem>
        </Tooltip>
    );
};

export default MenuItem;