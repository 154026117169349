const IconAdd= ({ width = 22, height = 22, fill = "#FFFFFF" }) => (
    <svg viewBox="0 0 24 24" width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Vector"
              d="M6 12H12M12 12H18M12 12V18M12 12V6"
              stroke={fill}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"/>
    </svg>
);

export default IconAdd;