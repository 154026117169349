import './App.css';
import React from 'react';
import Login from './components/login/login';
import Loader from './components/loader';
import Dashboard from './components/dashboard/dashboard';
import Asset from './components/asset/asset';
//import Navbar from './components/navbar';
import { useSelector } from 'react-redux'


import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles";

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import theme from './theme/theme';

function App() {

    const { user } = useSelector(state => state.user)
    const { loading, error } = useSelector((state) => state.api);
    const [show, setShow] = React.useState(false);
    const [navIsOpen, setNavIsOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        // setOpen(false);
        setShow(false)
    };

    React.useEffect(() => {
        if (error) setShow(true);
    }, [error])

    return (
        <ThemeProvider theme={theme}>
        <Loader show={loading}></Loader>
        <div className="App">
        <Snackbar
                open={show}

                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                //autoHideDuration={3000}

                onClose={handleClose}
                message={error}
                action={
                    <Button onClick={handleClose} size="medium">
                      Ok
                    </Button>
                }
            //action={action}
            />
            <BrowserRouter>
            <Routes>
                {(Date.now() < (user && user.login && user.login.exp && user.login.exp * 1000)) ? (
                    <>
                        <Route path='/' element={<Dashboard navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen}/>} />
                        <Route path='/Dashboard' element={<Dashboard navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen}/>} />
                        <Route path='/Asset' element={<Asset navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />} />
                    </>
                ) : (
                    <Route path='*' element={<Login />} />
                )}
                </Routes>
            </BrowserRouter>
        </div>
        </ThemeProvider>
    )
}

export default App;
