import React, { } from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate, useLocation } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import BannerLogo from '../../Assets/hubble_logo.svg';
import { logout } from "../../store/user"
import { useDispatch, useSelector } from 'react-redux'
import CreatableSelect from 'react-select/creatable';

import useMediaQuery from '@mui/material/useMediaQuery';
import { getAPI } from "../../store/api";
import MenuItem from './menuItem';
import IconDashboard from "../icons/iconDashboard";
import IconLogout from "../icons/iconLogout";
import IconAsset from "../icons/iconAsset";


const drawerWidth = 230;


const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function Navbar({listItems, searchData, open, setOpen}) {
    const matches = useMediaQuery('(max-width:600px)');
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.user)

    const dispatch = useDispatch()

    const [title, setTitle] = React.useState(useLocation().pathname.replace("/",""));
    const [searchItem, setSearchItem] = React.useState([]);
    const [searchField, setSearchField] = React.useState(false);
    const [focus, setFocus] = React.useState(false);


    const customStyles = {
        control: (base, state) => ({
            ...base,
          background: "#fff",
          fontSize: '0.8rem',
            textAlign: 'left',
          minWidth:(matches)?"200px":"400px",
            border: 0,
        }),
        menu: base => ({
          ...base,
          // override border radius to match the box
          borderRadius: 0,
          // kill the gap
          marginTop: 0,

        }),
        menuList: base => ({
          ...base,
          color:"#000",
          fontSize:"14px",
          //alignItems:"left",
          // kill the white space on first and last option
          padding: 0
        }),
      };

    React.useEffect(() => {
        if(listItems && listItems.length){
            setSearchItem(listItems)
        }

    },[listItems])

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: (matches) ? 0 : `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: (matches) ? 0 : `calc(${theme.spacing(7)} + 1px)`,
        },
    });

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" open={open} >
                <Toolbar sx={{ backgroundColor: "#26225b" }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 0,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ flex: 1 }} />
                    {(title === "Dashboard" || title === "")?<CreatableSelect styles={customStyles}
                        onBlur={()=>{setFocus(false)}}
                        onFocus={()=>{setFocus(true)}}
                        value={focus?searchField:(!focus && searchField.length <=1 )?searchField:(searchField && searchField.length)?[{"label": searchField.length+" Item(s)","value":""}]:""}
                        placeholder="Search..." onChange={(data)=>{if(data.length === 0){searchData([])} setSearchField(data);searchData(data)}} isMulti options={searchItem}/>:<></>}

                    <Box sx={{ flex: 1 }} />
                    <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                    <Chip variant="filled" sx={{
                        color: "#a3a9ffc9",
                        border: "1px solid #a3a9ff8a",
                    }} label={(user && user.detailUser && user.detailUser.orgName)?user.detailUser.orgName:"Company"}></Chip>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader sx={{display: 'flex', flexDirection: 'row', alignItems: 'top'}}>
                    <img alt={"HubbleNetwork"} src={BannerLogo} height={30}
                         style={{ flex: 1, marginTop: 20, marginBottom: 20}}/>

                    <IconButton onClick={handleDrawerClose} sx={{color: '#ffffff'}}>

                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <List sx={{pt: 3}}>
                    <MenuItem
                        title={title}
                        setTitle={setTitle}
                        open={open}
                        path="/Dashboard"
                        Icon={<IconDashboard
                            height={open ? 16 : 20}
                            width={open ? 16 : 20}
                            sx={{marginRight: open ? "0.6rem" : 0}}
                        />}
                        isMain={true}
                    />
                    <MenuItem
                        title={title}
                        setTitle={setTitle}
                        open={open}
                        path="/Asset"
                        Icon={<IconAsset
                            height={open ? 16 : 20}
                            width={open ? 16 : 20}
                            sx={{marginRight: open ? "0.6rem" : 0}}
                        />}
                    />
                </List>
                <Divider style={{backgroundColor: '#FFF', opacity:0.05}} />
                <List>
                <MenuItem
                        title={title}
                        setTitle={setTitle}
                        open={open}
                        path="Logout"
                        Icon={<IconLogout
                            fill="#ffffff"
                            height={open ? 14 : 18}
                            width={open ? 14 : 18}
                            sx={{marginRight: open ? "0.6rem" : 0}}
                        />}
                        onClick={() => {
                            dispatch(getAPI({
                                "method": "POST",
                                "data": {
                                    "token": "",
                                    "logout": {}
                                }
                            })).then(res => {
                                dispatch(logout())
                                navigate("/")
                            })
                        }}
                    />
                </List>
            </Drawer>

        </>
    );
}