const IconAsset = ({ width = 24, height = 24, fill = "#FFFFFF" }) => (
    <svg viewBox="0 0 16 16"  width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_34_87)">
                <path
                    d="M6.35075 5.33337H9.01742C9.36761 5.33337 9.71437 5.40235 10.0379 5.53636C10.3614 5.67037 10.6554 5.8668 10.903 6.11442C11.1507 6.36205 11.3471 6.65602 11.4811 6.97955C11.6151 7.30309 11.6841 7.64985 11.6841 8.00004V11.3299C11.6841 12.3907 11.2627 13.4082 10.5125 14.1583C9.76236 14.9085 8.74495 15.3299 7.68408 15.3299C6.62322 15.3299 5.6058 14.9085 4.85565 14.1583C4.10551 13.4082 3.68408 12.3907 3.68408 11.3299V8.00004C3.68408 7.2928 3.96503 6.61452 4.46513 6.11442C4.96523 5.61433 5.6435 5.33337 6.35075 5.33337Z"
                    stroke={fill} strokeMiterlimit="10"/>
                <path d="M7.68408 7.33679V9.3333" stroke={fill} strokeMiterlimit="10"/>
                <path
                    d="M4.38916 2.0384C4.82162 1.60528 5.33523 1.26167 5.90061 1.02723C6.46599 0.792791 7.07204 0.672119 7.6841 0.672119C8.29616 0.672119 8.90221 0.792791 9.46759 1.02723C10.033 1.26167 10.5466 1.60528 10.979 2.0384"
                    stroke={fill} strokeMiterlimit="10"/>
                <path
                    d="M5.79932 3.44846C6.29935 2.94885 6.97728 2.66821 7.68413 2.66821C8.39098 2.66821 9.06892 2.94885 9.56895 3.44846"
                    stroke={fill} strokeMiterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_34_87">
                    <rect width="9.36126" height="16" fill={fill} transform="translate(3)"/>
                </clipPath>
            </defs>
        </svg>
        );

export default IconAsset;