import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import "./dashMaps.style.css"

//mapboxgl.accessToken = process.env.MAPBOX_TOKEN;

const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

const addCircle = (map, placeData) => {
    placeData.forEach((obj, idx) => {
        ReturnCircle(parseFloat(obj.lat), parseFloat(obj.lng), obj.rad, obj.label, map)
    })
}

const ReturnCircle = (lat, lon, rad, location, map) => {

    if (!parseFloat(lat) || !parseFloat(lon)) {
        return;
    }
    var radius = (rad / 1000);
    var center = [parseFloat(lon), parseFloat(lat)];
    var options = { steps: 50, units: "kilometers", properties: { 'title': 'Mapbox DC' } };
    var circle = turf.circle(center, radius, options);
    map.addSource("circleData" + location, {
        type: "geojson",
        data: circle,
    });

    map.addLayer({
        id: "circle-fill" + location,
        type: "fill",
        //"minzoom":16,
        source: "circleData" + location,
        paint: {
            "fill-color": "#0098a4",
            "fill-opacity": 0.2,
        }
    });
}

const popContaint = (popupInfo, add) => {
    const durationContent = popupInfo.duration
        ? `<hr class="popup-divider">
           <span class="popup-duration">For ${popupInfo.duration}</span>`
        : "";

    return `
    <div class="popup-container">
      <div class="popup-header">
        <span class="popup-name">${popupInfo.assetName}</span>
      </div>
      <hr class="popup-divider">
      <span class="popup-additional">${add}</span>
      ${durationContent}
    </div>
  `;
};

const popWindow = (row, coordinates, map, oldPoint) => {
    const popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: false,
        offset: [0, -8]
    });
    const popups = document.getElementsByClassName("mapboxgl-popup");
    if (popups.length) popups[0].remove();
    map.flyTo({
        center: coordinates, zoom: 17, duration: oldPoint?returnDelay(row, oldPoint):2000, // Animate over 12 seconds
        essential: true
    })
    if (row.place.indexOf(',') === -1) {
        const add = row.place;
        popup.setLngLat(coordinates).setHTML(popContaint(row, add)).addTo(map);
    } else {

        const url = `${geocodeJson}?key=AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM&latlng=${coordinates[1]},${coordinates[0]}`;

        fetch(url).then(response => { return response.json() })
            .then(location => {
                const place = location.results[0];
                const add = place.formatted_address;
                popup.setLngLat(coordinates).setHTML(popContaint(row, add)).addTo(map);
            });
    }
}

const returnDelay = (row, oldPoint) => {
    const timers = [[0, 0], [10, 0.5], [50, 1], [700, 1], [1000, 2], [5000, 2], [20000, 3], [100000, 4], [4000000, 6], [10000000, 6]];
    var timer = 1000;
    if (!oldPoint.current) return timer;
    //console.log([row.lng, row.lat], [oldPoint.current.lng, oldPoint.current.lat])
    var from = turf.point([row.lng, row.lat]);
    var to = turf.point([oldPoint.current.lng, oldPoint.current.lat]);
    var options = { units: 'meters' };
    var distance = turf.distance(from, to, options);
    //console.log(distance);
    for (var j in timers) {
        //console.log(distance, timers[j][0]);
        if (distance <= timers[j][0]) {
            //console.log(timers[j][1], timers[j][1]*1000);
            timer = (timers[j][1] * 1000);
            break;
        }
    }
    //console.log(timer);
    return timer;
}

const HistoryMap = ({ rows, row, height, places }) => {

    const [dataRow] = React.useState(rows);
    const [place] = React.useState(places);
    const map = React.useRef(null);
    const oldPoint = React.useRef(null);

    const mapContainerRef = useRef(0);

    useEffect(() => {
        if (!row) return;

        popWindow(row, [row.lng, row.lat], map.current, oldPoint);
        setTimeout(() => {
            oldPoint.current = row;
        }, 500);

    }, [row])
    // Initialize map when component mounts
    useEffect(() => {
        //console.log(dataRow)
        if (!dataRow.length) return;
        map.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v12",
            center: [dataRow[0].lng, dataRow[0].lat],
            zoom: 12,
        });

        const bounds = new mapboxgl.LngLatBounds([dataRow[0].lng, dataRow[0].lat], [dataRow[0].lng, dataRow[0].lat]);

        for (const coord of dataRow) {
            bounds.extend([coord.lng, coord.lat]);
        }

        map.current.fitBounds(bounds, {
            padding: 50,
            bearing: 17.6,
            pitch: 45,
            maxZoom: 16
        });

        map.current.on("load", function () {

            const layers = map.current.getStyle().layers;
            const labelLayerId = layers.find(
                (layer) => layer.type === 'symbol' && layer.layout['text-field']
            ).id;

            // The 'building' layer in the Mapbox Streets
            // vector tileset contains building height data
            // from OpenStreetMap.
            map.current.addLayer(
                {
                    'id': 'add-3d-buildings',
                    'source': 'composite',
                    'source-layer': 'building',
                    'filter': ['==', 'extrude', 'true'],
                    'type': 'fill-extrusion',
                    'minzoom': 15,
                    'paint': {
                        'fill-extrusion-color': '#aaa',

                        // Use an 'interpolate' expression to
                        // add a smooth transition effect to
                        // the buildings as the user zooms in.
                        'fill-extrusion-height': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'height']
                        ],
                        'fill-extrusion-base': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'min_height']
                        ],
                        'fill-extrusion-opacity': 0.6
                    }
                },
                labelLayerId
            );

            addCircle(map.current, place)

            var pData = [];
            for (var k in place) {
                if (place[k].lng && place[k].lat) {
                    pData.push({ "type": "Feature", "properties": { "id": place[k].name, "name": place[k].label }, "geometry": { "type": "Point", "coordinates": [place[k].lng, place[k].lat, 0.0] } })
                }
            }

            map.current.addSource('places', {
                type: 'geojson',
                // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
                // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                data: {
                    "type": "FeatureCollection",
                    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                    "features": pData
                },
                cluster: true,
                clusterMaxZoom: 19, // Max zoom to cluster points on
                clusterRadius: 50 // 
            });

            map.current.addLayer({
                id: 'places_label',
                //type: 'circle',
                type: 'symbol',
                source: 'places',
                //"minzoom":16,
                filter: ['!', ['has', 'point_count']],
                'layout': {
                    //"icon-image": symbol + "-15",
                    //"icon-allow-overlap": true,
                    "text-field": ["string", ['get', 'name']],
                    "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                    "text-size": 13,
                    //"text-transform": "uppercase",
                    "text-letter-spacing": 0.05,
                    "text-offset": [0, -4]
                },
                "paint": {
                    "text-color": "#171442",
                    "text-halo-color": "#fff",
                    "text-halo-width": 1,
                    "text-halo-blur": 1
                },
            });

            var mData = [];
            for (var i in dataRow) {
                (function (i) {
                    if (String(0) === i) {
                        const marker = new mapboxgl.Marker().setLngLat([dataRow[i].lng, dataRow[i].lat]).addTo(map.current)
                        marker.getElement().addEventListener('click', (e) => {
                            popWindow(dataRow[i], [dataRow[i].lng, dataRow[i].lat], map.current, false);
                        })

                    } else {
                        mData.push({ 'type': 'Feature', 'geometry': { 'type': 'Point', 'coordinates': [dataRow[i].lng, dataRow[i].lat] }, 'properties': { 'assetName': dataRow[i].assetName, 'duration': dataRow[i].duration, 'reportTime': dataRow[i].reportTime, 'title': i, 'place': dataRow[i].place, lng: dataRow[i].lng, lat: dataRow[i].lat } })
                    }
                })(i);

            }

            map.current.addSource('points', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': mData
                }
            });

            map.current.addLayer({
                'id': 'circle',
                'type': 'circle',
                'source': 'points',
                'paint': {
                    'circle-color': '#5A64FF',
                    'circle-radius': 13,
                    'circle-opacity': 0.8,
                    //'circle-stroke-width': 1,
                    //'circle-stroke-color': '#fff'
                }
            });

            map.current.addLayer({
                'id': 'points',
                'type': 'symbol',
                'source': 'points',
                'layout': {
                    'text-field': [
                        'format',
                        ['upcase', ['get', 'title']],
                        { 'font-scale': 0.8 },
                    ],
                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],

                    "text-allow-overlap": false,
                    'text-offset': [0, -0.5],
                    'text-anchor': 'top'
                }
            });

            map.current.on('click', 'points', (e) => {
                var row = e.features[0].properties;
                const coordinates = [row.lng, row.lat];
                popWindow(row, coordinates, map.current, false);
            });
        });


        map.current.addControl(new mapboxgl.NavigationControl(), "top-right");

        map.current.on('resize', () => {
            map.current.resize();
        });

        // Clean up on unmount
        return () => map.current.remove();
    }, [dataRow, place]);
    return dataRow.length ? <div style={{ height: `${height}`, width: "100%" }} ref={mapContainerRef} /> : <>No Historical data.</>;
};

export default HistoryMap;