import * as React from 'react';
import mapboxgl from "mapbox-gl";
import moment from 'moment';

import * as turf from "@turf/turf";
import {useNavigate} from 'react-router-dom';
import "./dashMaps.style.css"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';


const addCircle = (map, placeData) => {
    placeData.forEach((obj, idx) => {
        ReturnCircle(parseFloat(obj.lat), parseFloat(obj.lng), obj.rad, obj.label, map)
    })
}

const ReturnCircle = (lat, lon, rad, location, map) => {

    if (!parseFloat(lat) || !parseFloat(lon)) {
        return;
    }
    var radius = (rad/1000);
    var center = [parseFloat(lon), parseFloat(lat)];
    var options = { steps: 50, units: "kilometers", properties: {'title': 'Mapbox DC'} };
    var circle = turf.circle(center, radius, options);
    map.addSource("circleData" + location, {
        type: "geojson",
        data: circle,
    });

    map.addLayer({
        id: "circle-fill" + location,
        type: "fill",
        //"minzoom":16,
        source: "circleData" + location,
        paint: {
            "fill-color": "#171442",
            "fill-opacity": 0.2,
        }
    });
}

const getData = (deviceData, search) => {

    if (Array.isArray(search) && search.length !== 0) {
        if(!search[0].latest || !Object.keys(search[0].latest).length)return false;
        //var jarr = {};
        const groupBY =search.filter((g)=>{ return g.type === "Group";})
        const assetBY =search.filter((g)=>{ return g.type === "Asset";})

        const gData = groupBY.length?deviceData.filter((item) => {
            return groupBY.map((ji)=>{ return ji.id}).includes(item.groupID)
        }):[];

        const aData = assetBY.length?deviceData.filter((item) => {
            return assetBY.map((ji)=>{ return ji.id}).includes(item.id)
        }):[];

        return [...aData, ...gData].map((item) => (
            {
                id: item.id,
                label: item.label,
                device: item.device,
                latest: item.latest
            }));
    }
    else {

        const dt = deviceData.filter((d)=> {return d.latest && Object.keys(d.latest).length}) ;

        return dt.map((item) => ({
            id: item.id,
            label: item.label,
            device: item.device,
            latest: item.latest
        }));
    }
}

const popContent = (popupInfo, add) => {
    const trendingIcon = `
        <svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Interface / Trending_Up">
            <path id="Vector" d="M20.0005 7L14.1543 12.9375C14.0493 13.0441 13.9962 13.0976 13.9492 13.1396C13.1899 13.8193 12.0416 13.8193 11.2822 13.1396C11.2352 13.0976 11.1817 13.0442 11.0767 12.9375C10.9716 12.8308 10.9191 12.7774 10.8721 12.7354C10.1127 12.0557 8.96397 12.0557 8.20461 12.7354C8.15771 12.7773 8.10532 12.8305 8.00078 12.9367L4 17M20.0005 7L20 13M20.0005 7H14" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
        </svg>`;

    const editIcon = `
        <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4443 5.68747L5.44587 14.6859C4.78722 15.3446 4.26719 16.1441 4.10888 17.062C3.94903 17.9888 3.89583 19.139 4.44432 19.6875C4.99281 20.236 6.14299 20.1828 7.0698 20.0229C7.98772 19.8646 8.78722 19.3446 9.44587 18.6859L18.4443 9.68747M14.4443 5.68747C14.4443 5.68747 17.4443 2.68747 19.4443 4.68747C21.4443 6.68747 18.4443 9.68747 18.4443 9.68747M14.4443 5.68747L18.4443 9.68747" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;

    const durationContent = popupInfo.duration
        ? `<hr class="popup-divider">
           <span class="popup-duration">For ${popupInfo.duration}</span>`
        : "";

    return `
    <div class="popup-container">
      <div class="popup-header">
        <span class="popup-name">${popupInfo.name}</span>
      </div>
      <hr class="popup-divider">
      <span class="popup-additional">${add}</span>
      ${durationContent}
      <div class="buttons">
        <button title="History" id="historySpan" class="actionItem">
             <span>History</span> 
             ${trendingIcon}
        </button>
        <button title="Edit" id="editSpan" class="actionItem">
             <span>Edit</span> 
             ${editIcon}
        </button>
      </div>
    </div>
  `;
};

export default function Maps({ search, openChart, deviceDataList, table2Map, Map2Table, places, navIsOpen}) {
    const map = React.useRef(0);
    const oldChart = React.useRef(0);
    const mapContainerRef = React.useRef(0);
    const [M2T, setM2T] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [nav, setNav] = React.useState(false);
    const [chart, setChart] = React.useState(false);
    const[deviceData, setDeviceData] =  React.useState(getData(deviceDataList, search, Map2Table))
    const preSearch = React.useRef(search);
    const tableToMap = React.useRef(table2Map);
    const navigate = useNavigate()

    React.useEffect(()=>{
        if(nav){
            console.log("here nav");
            navigate(nav);
        }

    },[nav, navigate])

    React.useEffect(()=>{
       if(!navIsOpen && map.current) {
           map.current.resize();
       }
    }, [navIsOpen])

    React.useEffect(()=>{
        if(chart && oldChart.current !== chart){
            console.log("here chart");
            openChart(chart);

            oldChart.current = chart;
        }
    },[chart, openChart])

    const popWindow = (row, coordinates, map) => {
        //console.log(row);

        const popup = new mapboxgl.Popup({
            closeButton: true,
            closeOnClick: false,
            offset: [0, -8]
        });
        const popups = document.getElementsByClassName("mapboxgl-popup");
        if (popups.length) popups[0].remove();

        if (row.place.indexOf(',') === -1) {
            const add = row.place;
            popup.setLngLat(coordinates).setHTML(popContent(row,add)).addTo(map);

            document.getElementById("historySpan").addEventListener("click",function(){
                //openChart({ id: key, assetName: multi[x].label });
                setChart({ id: row.id, assetName: row.name });
            });
            document.getElementById("editSpan").addEventListener("click",function(){
                setNav("/Asset?id="+row.name);
            });

        } else {

            const url = `${geocodeJson}?key=AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM&latlng=${coordinates[1]},${coordinates[0]}`;

            fetch(url).then(response => { return response.json() })
                .then(location => {
                    const place = location.results[0];
                    const add = place.formatted_address;
                    popup.setLngLat(coordinates).setHTML(popContent(row,add)).addTo(map);

                    document.getElementById("historySpan").addEventListener("click",function(){
                        //openChart({ id: key, assetName: multi[x].label });
                        setChart({ id: row.id, assetName: row.name });
                    });
                    document.getElementById("editSpan").addEventListener("click",function(){
                        setNav("/Asset?id="+row.name);
                    });
                });
        }
    }

    React.useEffect(()=>{
        //console.log("load once");
        map.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v12",
            center: [-98.35,39.50],
            zoom: 15,
            pitch: 45,
            bearing: -17.6,
            antialias: true
        });

        map.current.on("load", function () {
            setLoaded(true);
            map.current.resize();

            const layers = map.current.getStyle().layers;
            const labelLayerId = layers.find(
                (layer) => layer.type === 'symbol' && layer.layout['text-field']
            ).id;

            map.current.addLayer(
                {
                    'id': 'add-3d-buildings',
                    'source': 'composite',
                    'source-layer': 'building',
                    'filter': ['==', 'extrude', 'true'],
                    'type': 'fill-extrusion',
                    'minzoom': 15,
                    'paint': {
                        'fill-extrusion-color': '#aaa',

                        // Use an 'interpolate' expression to
                        // add a smooth transition effect to
                        // the buildings as the user zooms in.
                        'fill-extrusion-height': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'height']
                        ],
                        'fill-extrusion-base': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'min_height']
                        ],
                        'fill-extrusion-opacity': 0.6
                    }
                },
                labelLayerId
            );
            map.current.addControl(new mapboxgl.NavigationControl());
            map.current.on('resize', () => {
                map.current.resize();
            });
            addCircle(map.current, places)

            var pData = [];
            for (var k in places) {
                if (places[k].lng && places[k].lat) {
                    pData.push({ "type": "Feature", "properties": { "id": places[k].name, "name": places[k].label }, "geometry": { "type": "Point", "coordinates": [places[k].lng, places[k].lat, 0.0] } })
                }
                //      k++;
                //}
            }

            map.current.addSource('places', {
                type: 'geojson',
                // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
                // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                data: {
                    "type": "FeatureCollection",
                    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                    "features": pData
                },
                cluster: true,
                clusterMaxZoom: 19, // Max zoom to cluster points on
                clusterRadius: 50 // 
            });

            map.current.addLayer({
                id: 'places_label',
                //type: 'circle',
                type: 'symbol',
                source: 'places',
                //"minzoom":16,
                filter: ['!', ['has', 'point_count']],
                'layout': {
                    "text-allow-overlap": true,
                    "text-field": ["string", ['get', 'name']],
                    "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                    "text-size": 13,
                    "text-letter-spacing": 0.05,
                    "text-offset": [0, -4]
                },
                "paint": {
                    "text-color": "#171442",
                    "text-halo-color": "#fff",
                    "text-halo-width": 1,
                    "text-halo-blur":1
                }
            });
        });

        return () => map.current.remove();
    },[places])

    React.useEffect(()=>{

        if(preSearch.current !==  search){
            setDeviceData(getData(deviceDataList, search))

        const layrs = ["clusters","unclustered-point","tile_label","cluster-count","tilesSingle_newLabel","tilesSingle-newPoint"];
            const src = ["tiles","tilesSingle"];
            for(var m in layrs)
                if(map.current.getLayer(layrs[m])){
                    //console.log("removing layer", layrs[m])
                    map.current.removeLayer(layrs[m]);
                }

            for(var x in src)
                if(map.current.getSource(src[x])){
                    //console.log("removing src ", src[x]);
                    map.current.removeSource(src[x]);
                }

                preSearch.current = search;
        }

    },[search, deviceDataList])

    React.useEffect(() => {
        if(loaded === false || !deviceData.length) return;
        map.current.setCenter([deviceData[0].latest.lng, deviceData[0].latest.lat]);
        const bounds = new mapboxgl.LngLatBounds([(deviceData[0].latest.lng?deviceData[0].latest.lng:-98.35), (deviceData[0].latest.lat?deviceData[0].latest.lat:39.50)], [(deviceData[0].latest.lng?deviceData[0].latest.lng:-98.35), (deviceData[0].latest.lat?deviceData[0].latest.lat:39.50)]);

        for (var j in deviceData) {
            //console.log(deviceData[j])
            if(deviceData[j].latest.lng && deviceData[j].latest.lat){
                bounds.extend([deviceData[j].latest.lng, deviceData[j].latest.lat]);
            }
        }

        map.current.fitBounds(bounds, {
            maxZoom: 17,
            padding: 50,
            bearing: 17.6,
            pitch: 45,
        });


        if(loaded){

            var Gdata = [];

            for (var y in deviceData) {
                if (deviceData[y].latest.lng && deviceData[y].latest.lat) {
                    let place = (deviceData[y].latest.place)?deviceData[y].latest.place.label:deviceData[y].latest.lng+","+deviceData[y].latest.lat;
                    Gdata.push({ "type": "Feature", "properties": { "id": deviceData[y].id, "name": deviceData[y].label,"place":place, "checkIn": moment.unix(deviceData[y].latest.utc).fromNow(), "duration": (deviceData[y].latest.dur)?moment().subtract(deviceData[y].latest.dur, 'seconds').fromNow(true):"", "felt": null, "tsunami": 0 }, "geometry": { "type": "Point", "coordinates": [deviceData[y].latest.lng, deviceData[y].latest.lat, 0.0] } })
                }
            }

            map.current.addSource('tiles', {
                type: 'geojson',
                // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
                // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                data: {
                    "type": "FeatureCollection",
                    "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
                    "features": Gdata
                },
                cluster: true,
                clusterMaxZoom: 18, // Max zoom to cluster points on
                clusterRadius: 50 //
            });

            map.current.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'tiles',
                filter: ['has', 'point_count'],
                paint: {
                    // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
                    // with three steps to implement three types of circles:
                    //   * Blue, 20px circles when point count is less than 100
                    //   * Yellow, 30px circles when point count is between 100 and 750
                    //   * Pink, 40px circles when point count is greater than or equal to 750
                    'circle-color': [
                        'step',
                        ['get', 'point_count'],
                        '#5A64FF', 10,
                        '#7f87fa', 50,
                        '#969dff', 750,
                        '#c7cbff'
                    ],
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        30,
                        35,
                        40
                    ],
                    //"icon-allow-overlap": true
                }
            });

            map.current.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                //type: 'symbol',
                source: 'tiles',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': '#5A64FF',
                    'circle-radius': 7,
                    'circle-stroke-width': 3,
                    'circle-stroke-color': 'black',
                    'circle-opacity': 0.6

                }
            });

            map.current.addLayer({
                id: 'tile_label',
                //type: 'circle',
                type: 'symbol',
                source: 'tiles',
                filter: ['!', ['has', 'point_count']],
                'layout': {
                    //'text-field': "test",

                    'text-field': ["string", ['get', 'name']],
                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                    'text-size': 10,
                    //'text-letter-spacing': 0,
                    //'text-halo-width':0,
                    //'text-emissive-strength':0,
                    //'text-allow-overlap': true,
                    //"text-transform": "uppercase",
                    "text-letter-spacing": 0.05,
                    'text-offset': [0, 1.8],
                },
                'paint': {
                    "text-color": "#171442",
                    "text-halo-color": "#fff",
                    "text-halo-width": 1,
                    "text-halo-blur": 1
                }
                /*layout: {
                    "icon-image": "cat"
                },*/

            });

            map.current.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'tiles',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': ['get', 'point_count_abbreviated'],
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 18,
                    'text-allow-overlap': true
                }
            });

            // inspect a cluster on click
            map.current.on('click', 'clusters', (e) => {
                e.clickOnLayer = true;

                const features = map.current.queryRenderedFeatures(e.point, {
                    layers: ['clusters']
                });
                const clusterId = features[0].properties.cluster_id;

                const pointCount = features[0].properties.point_count;
                const clusterSource = map.current.getSource('tiles');
                //console.log("clusterId",clusterId);
                if (map.current.getZoom() < 18) {
                    map.current.getSource('tiles').getClusterExpansionZoom(
                        clusterId,
                        (err, zoom) => {
                            if (err) return;
                            map.current.flyTo({
                                center: features[0].geometry.coordinates,
                                zoom: zoom
                            });
                        }
                    );
                    /*clusterSource.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
                            console.log("here");
                            map.current.flyTo({
                                //center: [e.lngLat.lng, e.lngLat.lat],//features[0].geometry.coordinates,
                                center: features[1].geometry.coordinates,
                                //center: [deviceData[0].lng, deviceData[0].lat],
                                zoom: 16,
                                duration: 1000
                                //zoom: 16
                            });
                        })*/
                }
                clusterSource.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
                    // Print cluster leaves in the console
                    setM2T(features.map((item) => {
                        return item.properties.id
                    }));
                })
                //}

            });

            map.current.on('click', function (e) {
                if (e.clickOnLayer) {
                    return;
                }
                setM2T(false)
            });

            // When a click event occurs on a feature in
            // the unclustered-point layer, open a popup at
            // the location of the feature, with
            // description HTML from its properties.
            map.current.on('click', 'unclustered-point', (e) => {
                const coordinates = e.features[0].geometry.coordinates.slice();
                //const mag = e.features[0].properties.mag;
                //const asset = e.features[0].properties.name;
                //const id = e.features[0].properties.id;
                //const checkIn = e.features[0].properties.checkIn;
                //const Duration = e.features[0].properties.duration;
                //const tsunami = e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

                // Ensure that if the map is zoomed out such that
                // multiple copies of the feature are visible, the
                // popup appears over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                popWindow(e.features[0].properties, coordinates, map.current);
                /*const popups = document.getElementsByClassName("mapboxgl-popup");
                if (popups.length) popups[0].remove();

                const url = `${geocodeJson}?key=AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM&latlng=${e.lngLat.lat},${e.lngLat.lng}`;
                fetch(url).then(response => { return response.json() })
                    .then(location => {
                        const place = location.results[0];
                        const add = place.formatted_address;
                        //console.log(Duration);
                        let html = "";
                        html+="<div style='width:185px;margin: auto;padding:0px;'><span style='font-size:11px;'></span> <span style='font-size:11px;font-weight:bold;'>" + asset + "</span><hr style='margin: 0em;border-width: 1px;'><span style='font-size:11px;'></span> <span style='font-size:11px;font-weight:bold;'>" + add + "</span><hr style='margin: 0em;border-width: 1px;'><div style=''></div>";
                        html+= (Duration)?"<span style='font-size:11px;'></span> <span style='font-size:11px;font-weight:bold;'>" + Duration + "</span><hr style='margin: 0em;border-width: 1px;'></hr>":"";

                        new mapboxgl.Popup()
                            .setLngLat(coordinates)
                            .setHTML(html)
                            .addTo(map.current);
                    });*/

            });

            map.current.on('mouseenter', 'clusters', () => {
                map.current.getCanvas().style.cursor = 'pointer';
            });
            map.current.on('mouseleave', 'clusters', () => {
                map.current.getCanvas().style.cursor = '';
            });
        }
        // Clean up on unmount
    }, [deviceData, loaded, places]);

    React.useEffect(() => {
        Map2Table(M2T)
    }, [M2T, Map2Table])

    React.useEffect(() => {
        const assignVal = ()=>{
            tableToMap.current = table2Map;
        }
        if(!table2Map){
            assignVal();
            return;
        }
        if (tableToMap.current !== table2Map ){
            var Gdata = [];
            for(let a in deviceData){
                if(deviceData[a].id === table2Map[0].id){
                    let place = (deviceData[a].latest.place)?deviceData[a].latest.place.label:deviceData[a].latest.lng+","+deviceData[a].latest.lat;
                    Gdata.push({ "type": "Feature", "properties": { "id": deviceData[a].id, "name": deviceData[a].label, "place":place, "checkIn": moment.unix(deviceData[a].latest.utc).fromNow(), "duration": (deviceData[a].latest.dur)?moment().subtract(deviceData[a].latest.dur, 'seconds').fromNow(true):"", "felt": null, "tsunami": 0 }, "geometry": { "type": "Point", "coordinates": [deviceData[a].latest.lng, deviceData[a].latest.lat, 0.0] } })
                }
            }

            if(Gdata.length){
                const layrs = ["tilesSingle_newLabel","tilesSingle-newPoint"];
                const src = ["tilesSingle"];
                for(var m in layrs)
                    if(map.current.getLayer(layrs[m])){
                        map.current.removeLayer(layrs[m]);
                    }

                for(var x in src)
                    if(map.current.getSource(src[x])){
                        map.current.removeSource(src[x]);
                    }

                map.current.flyTo({
                    center: Gdata[0].geometry.coordinates, zoom: 18, duration: 2000, // Animate over 12 seconds
                    essential: true
                })
                map.current.addSource('tilesSingle', {
                    type: 'geojson',
                    // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
                    // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                    data: {
                        "type": "FeatureCollection",
                        "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                        "features": Gdata
                    },
                    cluster: true,
                    clusterMaxZoom: 19, // Max zoom to cluster points on
                    clusterRadius: 50 //
                });

                map.current.addLayer({
                    id: 'tilesSingle-newPoint',
                    type: 'circle',
                    //type: 'symbol',
                    source: 'tilesSingle',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-color': '#5A64FF',
                        'circle-radius': 7,
                        'circle-stroke-width': 3,
                        'circle-stroke-color': 'black',
                        'circle-opacity': 0.6

                    }
                });

                map.current.addLayer({
                    id: 'tilesSingle_newLabel',
                    type: 'symbol',
                    source: 'tilesSingle',
                    filter: ['!', ['has', 'point_count']],
                    'layout': {
                        'text-field': ["string", ['get', 'name']],
                        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                        'text-size': 10,
                        'text-allow-overlap': true,
                        "text-letter-spacing": 0.05,
                        'text-offset': [0, 1.8],
                    },
                    'paint': {
                        "text-color": "#171442",
                        "text-halo-color": "#fff",
                        "text-halo-width": 1,
                        "text-halo-blur":1
                    }
                });
                popWindow(Gdata[0].properties,Gdata[0].geometry.coordinates, map.current);
            }
        }
        assignVal();
    }, [deviceData, openChart, table2Map])


    return <div style={{ height: "100%", width: "100%" }} ref={mapContainerRef} ><div id="zoomvalue"></div><div id="zoombehavior"></div></div>;
}