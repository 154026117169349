import { createTheme } from "@mui/material";

const theme = createTheme({
        palette: {
            hubblePurple: {
                main: '#5A64FF', 
            },
            hubblePurpleLight: {
                main: '#7b83ff', 
            },
            darkPurple: {
                main: '#171442',
            },
            root: {
                color: '#d8d8d8',
            },
            primary: {
                main: "#5A64FF"
            },
            secondary: {
                main: "#171442"
            },
            text: {
                primary: "#1e1c40",
                secondary: "#393853",
            },
        
        },

        components: {
            MuiDrawer: {
                styleOverrides:{
                    root: {
                        backgroundColor: '#171442',
                    },
                    paper: {
                        backgroundColor: '#171442',
                    }
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        fontSize: '16sx',
                        fontWeight: '400'
                    },
                },
            },
            MuiDataGrid: {
                styleOverrides: {
                    columnHeader: {
                        "&:focus-within": {
                            outline: "none",
                        },
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    // row with column names
                    head: {
                        "&:hover": {
                            backgroundColor: "transparent !important",
                        },
                        "& .MuiTableCell-root": {
                            padding: '10px !important',
                        },
                    },
                    root: {
                        // row with group name
                        "&:first-of-type": {
                            backgroundColor: "#ededed",
                            "& td" : {
                                color: "#454545 !important",
                            },
                            "& .MuiTableCell-root": {
                                padding: '4px 20px',
                            },
                            "&:hover": {
                                backgroundColor: "#e6e6e6 !important",
                            }
                        },
                        "&:nth-of-type(odd)": {
                            backgroundColor: "#f3f3f3",
                        },
                        "&:last-child td, &:last-child th": {
                            border: 0,
                        },
                        "&:hover": {
                            backgroundColor: "#e6e6e6 !important",
                            cursor: 'pointer',
                        },
                    },
                },
            },
            MuiTableCell:{
                styleOverrides: {
                    head: {
                        backgroundColor: "#e6e6e6",
                    },
                    root: {
                        background: "transparent",
                        padding: '10px 16px 10px',
                    },
                }
            },
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none', // Removes shadow
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none', // Removes shadow from all Paper components (optional)
                    },
                },
            },
    
        }
    }
)

export default theme;